import React from 'react'

import {Navbar, Nav, NavDropdown, Container} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import './Navbar.css';


class NavbarExport extends React.Component{
    render(){

        return(
            
        <Navbar collapseOnSelect expand="lg" variant="dark" sticky="top" id="navtop" >
            <Container>
            <Navbar.Brand as={NavLink} to="/"> LinkEvolutionPro </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link eventKey="3" as={NavLink} to="/updated-card-shop-guide"> Card Shop Guide </Nav.Link>
                        <Nav.Link eventKey="7" as={NavLink} to="/challenge-mode-guide"> Challenge Mode Guide</Nav.Link>
                        <Nav.Link eventKey="4" as={NavLink} to="/forbidden-limited-list"> Forbidden & Limited List </Nav.Link>
                        <Nav.Link eventKey="8" as={NavLink} to="/Articles"> Articles </Nav.Link>
                        <Nav.Link eventKey="1" as={NavLink} to="/about-us"> About Us </Nav.Link>
                        <Nav.Link eventKey="2" as={NavLink} to="/privacy-policy-2"> Privacy Policy </Nav.Link>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        );
    }
}

export default NavbarExport;