import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import { Link } from 'react-router-dom';

import './testing.css';

class TestComponent extends React.Component {
    render() {
        return (
            <div className="home-deck">
                <Link to={this.props.linkTo} >
                    <div className="home-deck_cards">
                        <Image src={"/images/" + this.props.imageSlug + ".jpg"} fluid id="deck-image"></Image>
                    </div>
                </Link>

                <div className="home-deck_bio">
                    <Link to={this.props.linkTo} >
                        <p className="deck_name">{this.props.title}</p>
                        <p className="deck_author">Deck Type: {this.props.deckType}</p>
                    </Link>
                </div>
            </div>
        )
    }
}

class HighlightedPosts extends React.Component {
    render() {
        return (
            <Col md={4} lg={4} xs={12} id="articleCol" className={"post-" + this.props.post}>
                <Link to={this.props.linkTo} >
                    <div className="article-box">
                        <h3 className="article-title">{this.props.title}</h3>
                        <Image src={"/images/thumbnails/" + this.props.image} fluid id="article-image"></Image>
                    </div>
                </Link>
            </Col>
        )
    }
}

class Test extends React.Component {

    componentDidMount(){
        if(document.getElementById('NewAnchor')){
            return null
          } else{
            window['nitroAds'].createAd('NewAnchor', {
              "demo": false, //DISABLE THIS WHEN BUILDING
              "refreshLimit": 10,
              "refreshTime": 30,
              "format": "anchor",
              "anchor": "bottom",
              "report": {
                "enabled": true,
                "wording": "Report Ad",
                "position": "top-right"
              },
              "mediaQuery": "(min-width: 1025px), (min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
            });
          }

          window['nitroAds'].createAd('topLeaderboard', {
            "demo": false,
            "refreshLimit": 10,
            "refreshTime": 30,
            "renderVisibleOnly": false,
            "refreshVisibleOnly": true,
            "sizes": [
              [
                "320",
                "50"
              ]
            ],
            "report": {
              "enabled": true,
              "wording": "Report Ad",
              "position": "top-right"
            },
            "mediaQuery": "(min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
          });
  
          window['nitroAds'].createAd('topLeaderboard', {
            "demo": false,
            "refreshLimit": 10,
            "refreshTime": 30,
            "renderVisibleOnly": false,
            "refreshVisibleOnly": true,
            "sizes": [
              [
                "970",
                "90"
              ]
            ],
            "report": {
              "enabled": true,
              "wording": "Report Ad",
              "position": "top-right"
            },
            "mediaQuery": "(min-width: 1025px)"
          });
  
          window['nitroAds'].createAd('bottomLeaderboard', {
              "demo": false,
              "refreshLimit": 10,
              "refreshTime": 30,
              "renderVisibleOnly": false,
              "refreshVisibleOnly": true,
              "sizes": [
                [
                  "728",
                  "90"
                ],
                [
                  "970",
                  "90"
                ]
              ],
              "report": {
                "enabled": true,
                "wording": "Report Ad",
                "position": "top-right"
              },
              "mediaQuery": "(min-width: 1025px)"
            });
      
            window['nitroAds'].createAd('bottomLeaderboard', {
              "demo": false,
              "refreshLimit": 10,
              "refreshTime": 30,
              "renderVisibleOnly": false,
              "refreshVisibleOnly": true,
              "sizes": [
                [
                  "300",
                  "250"
                ],
                [
                  "300",
                  "600"
                ]
              ],
              "report": {
                "enabled": true,
                "wording": "Report Ad",
                "position": "top-right"
              },
              "mediaQuery": "(min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
            });
    }


    render() {

        return (

            <Container field="md" className="AboutPage">
                <Col id="about-content" lg={12} md={12}>
                    <div class="advertisement-box" id="topLeaderboard"></div>
                </Col>
                <Row id="content">
                    <HighlightedPosts title="UPDATED Card Shop Guide!" image="cardshop.jpg" linkTo="updated-card-shop-guide" post="1"></HighlightedPosts>
                    <HighlightedPosts title="Yu-Gi-Oh! Rush Duel: Saikyou Battle Royale!! Announced" image="rushduelannounce.jpg" linkTo="article/yu-gi-oh-rush-duel-saikyou-battle-royale-announced" post="2"></HighlightedPosts>
                    <HighlightedPosts title="New Update March 24th: Here’s Whats New!" image="marchupdate.jpg" linkTo="article/march-24th-update" post="3"></HighlightedPosts>
                    <HighlightedPosts title="UPDATED Challenge Mode Guide!" image="challenge.jpg" linkTo="challenge-mode-guide" post="4"></HighlightedPosts>
                    <HighlightedPosts title="Will Link Evolution Receive an Update?" image="update.jpg" linkTo="article/will-yu-gi-oh-legacy-of-the-duelist-link-evolution-receive-an-update" post="5"></HighlightedPosts>
                    <HighlightedPosts title="Your Guide To Master Rule 5" image="mr5.jpg" linkTo="article/master-rule-5-guide" post="6"></HighlightedPosts>
                    <Col id="about-content" lg={12} md={12}>
                        <h2 className="SubTitle">Popular Decks</h2>
                    </Col>
                    <TestComponent title="Exodia DP Farm" imageSlug="exodia" deckType="Guide" linkTo="guide/how-to-farm-duel-points-dp-easily"></TestComponent>
                    <TestComponent title="Blue-Eyes Chaos MAX" imageSlug="chaosmax" deckType="Competitive" linkTo="guide/deck-showcase-blue-eyes-chaos-max-dragon"></TestComponent>
                    <TestComponent title="Pure Thunder Dragon" imageSlug="purethunder" deckType="Competitive" linkTo="guide/deck-showcase-pure-thunder-dragons"></TestComponent>
                    <Col id="about-content" lg={12} md={12}>
                        <h5 className="SubTitleMini"><a href="www.linkevolutionpro.com">View More</a></h5>
                    </Col>
                </Row>
                <Col id="about-content" lg={12} md={12}>
                    <div class="advertisement-box" id="bottomLeaderboard"></div>
                </Col>
            </Container>
        )


    }

}

export default Test