import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import CardData from '../components/data/cardshopdata.json';
import './table.css';

const columns = [{
    dataField: 'Name',
    text: 'Card Name',
    headerStyle: {
        backgroundColor: '#1d1f29',
        color: 'white'
    }
}, {
    dataField: 'Location',
    text: 'Booster Pack Location',
    headerStyle: {
        backgroundColor: '#1d1f29',
        color: 'white'
    }
}];

const rowStyle = {
    backgroundColor: '#272937',
    color: 'white',
}

const { SearchBar } = Search;


class CardShop extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            //cards:CardData,
        };
    }

    componentDidMount(){
      setTimeout(function(){window['nitroAds'].createAd('bottomLeaderboard', {
        "demo": false,
        "refreshLimit": 10,
        "refreshTime": 30,
        "renderVisibleOnly": false,
        "refreshVisibleOnly": true,
        "sizes": [
          [
            "728",
            "90"
          ],
          [
            "970",
            "90"
          ]
        ],
        "report": {
          "enabled": true,
          "wording": "Report Ad",
          "position": "top-right"
        },
        "mediaQuery": "(min-width: 1025px)"
      });}, 2000)

      setTimeout(function(){window['nitroAds'].createAd('bottomLeaderboard', {
        "demo": false,
        "refreshLimit": 10,
        "refreshTime": 30,
        "renderVisibleOnly": false,
        "refreshVisibleOnly": true,
        "sizes": [[
          "300",
          "250"
        ],
          [
            "300",
            "600"
          ]
        ],
        "report": {
          "enabled": true,
          "wording": "Report Ad",
          "position": "top-right"
        },
        "mediaQuery": "(min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
      });}, 2000)

      setTimeout(function(){window['nitroAds'].createAd('topLeaderboard', {
        "demo": false,
        "refreshLimit": 10,
        "refreshTime": 30,
        "renderVisibleOnly": false,
        "refreshVisibleOnly": true,
        "sizes": [
          [
            "320",
            "50"
          ]
        ],
        "report": {
          "enabled": true,
          "wording": "Report Ad",
          "position": "top-right"
        },
        "mediaQuery": "(min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
      });}, 500)

      window['nitroAds'].createAd('topLeaderboard', {
        "demo": false,
        "refreshLimit": 10,
        "refreshTime": 30,
        "renderVisibleOnly": false,
        "refreshVisibleOnly": true,
        "sizes": [
          [
            "970",
            "90"
          ]
        ],
        "report": {
          "enabled": true,
          "wording": "Report Ad",
          "position": "top-right"
        },
        "mediaQuery": "(min-width: 1025px)"
      });

      if(document.getElementById('NewAnchor')){
        return null
      } else{
        window['nitroAds'].createAd('NewAnchor', {
          "demo": false, //DISABLE THIS WHEN BUILDING
          "refreshLimit": 10,
          "refreshTime": 30,
          "format": "anchor",
          "anchor": "bottom",
          "report": {
            "enabled": true,
            "wording": "Report Ad",
            "position": "top-right"
          },
          "mediaQuery": "(min-width: 1025px), (min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
        });
      }
    }

    render(){

        const options = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            showTotal: true,
            totalSize: CardData.length
          };

          const contentTable = ({ paginationProps, paginationTableProps }) => (
            <div>
              <ToolkitProvider
                keyField="Name"
                columns={ columns }
                data={ CardData }
                search
              >
                {
                  toolkitprops => (
                    <div>
                      <SearchBar { ...toolkitprops.searchProps } />
                      <BootstrapTable
                        striped
                        condensed
                        bordered={ false }
                        { ...toolkitprops.baseProps }
                        { ...paginationTableProps }
                        rowStyle={ rowStyle }
                      />
                    </div>
                  )
                }
              </ToolkitProvider>
              <PaginationListStandalone { ...paginationProps } />
            </div>
          );
      

        return(     

            <Container field="md" className="AboutPage">
                <Row id="content">
                    <Col id="about-content">
                        <div class="advertisement-box" id="topLeaderboard"></div> 
                        <h4>Ultimate Card Shop Guide</h4>
                        <h3>Last Update: March 23rd 2020</h3>
                        <p>Finding the exact card you need in Yu-Gi-Oh! Legacy of the Duelist: Link Evolution can be a real pain – especially when cards are seemingly placed in completely unrelated character packs! Our card shop guide below lists every single card available in game as of March 24th 2020 and where you can find them, including all the cards found in the new update for the game. Also, if you want an easier way of finding some of these cards in-game; be sure to check out our Updated Challenge Mode Guide – which will assist you in finding powerful cards such as Ash Blossom & Joyous Spring and more!</p>
                        <PaginationProvider pagination={ paginationFactory(options) }>
                            { contentTable }
                        </PaginationProvider>
                        <div class="advertisement-box" id="bottomLeaderboard"></div>   
                    </Col>     
                </Row>
            </Container>               



        )
    }

}

export default CardShop;