import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import CardData from '../components/data/challengedata.json';
import './table.css';

const columns = [{
    dataField: 'Card Name',
    text: 'Card Name',
    headerStyle: {
        backgroundColor: '#1d1f29',
        color: 'white'
    }
}, {
    dataField: 'Duelist Challenge',
    text: 'Duelist Challenge',
    headerStyle: {
        backgroundColor: '#1d1f29',
        color: 'white'
    }
}, {
    dataField: 'Series',
    text: 'Series',
    headerStyle: {
        backgroundColor: '#1d1f29',
        color: 'white'
    }
}];

const rowStyle = {
    backgroundColor: '#272937',
    color: 'white',
}

const { SearchBar } = Search;


class Challenge extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            //cards:CardData,
        };
    }

    componentDidMount(){
      setTimeout(function(){window['nitroAds'].createAd('bottomLeaderboard', {
        "demo": false,
        "refreshLimit": 10,
        "refreshTime": 30,
        "renderVisibleOnly": false,
        "refreshVisibleOnly": true,
        "sizes": [
          [
            "728",
            "90"
          ],
          [
            "970",
            "90"
          ]
        ],
        "report": {
          "enabled": true,
          "wording": "Report Ad",
          "position": "top-right"
        },
        "mediaQuery": "(min-width: 1025px)"
      });}, 2000)

      setTimeout(function(){window['nitroAds'].createAd('bottomLeaderboard', {
        "demo": false,
        "refreshLimit": 10,
        "refreshTime": 30,
        "renderVisibleOnly": false,
        "refreshVisibleOnly": true,
        "sizes": [
          [
            "300",
            "250"
          ],
          [
            "300",
            "600"
          ]
        ],
        "report": {
          "enabled": true,
          "wording": "Report Ad",
          "position": "top-right"
        },
        "mediaQuery": "(min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
      });}, 2000)

      setTimeout(function(){window['nitroAds'].createAd('topLeaderboard', {
        "demo": false,
        "refreshLimit": 10,
        "refreshTime": 30,
        "renderVisibleOnly": false,
        "refreshVisibleOnly": true,
        "sizes": [
          [
            "320",
            "50"
          ]
        ],
        "report": {
          "enabled": true,
          "wording": "Report Ad",
          "position": "top-right"
        },
        "mediaQuery": "(min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
      });}, 500)

      window['nitroAds'].createAd('topLeaderboard', {
        "demo": false,
        "refreshLimit": 10,
        "refreshTime": 30,
        "renderVisibleOnly": false,
        "refreshVisibleOnly": true,
        "sizes": [
          [
            "970",
            "90"
          ]
        ],
        "report": {
          "enabled": true,
          "wording": "Report Ad",
          "position": "top-right"
        },
        "mediaQuery": "(min-width: 1025px)"
      });

      if(document.getElementById('NewAnchor')){
        return null
      } else{
        window['nitroAds'].createAd('NewAnchor', {
          "demo": false, //DISABLE THIS WHEN BUILDING
          "refreshLimit": 10,
          "refreshTime": 30,
          "format": "anchor",
          "anchor": "bottom",
          "report": {
            "enabled": true,
            "wording": "Report Ad",
            "position": "top-right"
          },
          "mediaQuery": "(min-width: 1025px), (min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
        });
      }
    }

    render(){

        const options = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            showTotal: true,
            totalSize: CardData.length
          };

          const contentTable = ({ paginationProps, paginationTableProps }) => (
            <div>
              <ToolkitProvider
                keyField="Name"
                columns={ columns }
                data={ CardData }
                search
              >
                {
                  toolkitprops => (
                    <div>
                      <SearchBar { ...toolkitprops.searchProps } />
                      <BootstrapTable
                        striped
                        condensed
                        bordered={ false }
                        { ...toolkitprops.baseProps }
                        { ...paginationTableProps }
                        rowStyle={ rowStyle }
                      />
                    </div>
                  )
                }
              </ToolkitProvider>
              <PaginationListStandalone { ...paginationProps } />
            </div>
          );
      

        return(     

            <Container field="md" className="AboutPage">
                <Row id="content">
                    <Col id="about-content">
                        <div class="advertisement-box" id="topLeaderboard"></div> 
                        <h4>Updated Challenge Mode Guide</h4>
                        <h3>Last Update: March 23rd 2020</h3>
                        <p>Yu-Gi-Oh! Legacy of the Duelist: Link Evolution’s Challenge Mode might be challenging for some, but it can be an extremely useful tool that can be used to obtain some harder to find cards, both generically good such as Ash Blossom & Joyous Spring , Ghost Ogre & Snow Rabbit or archetype cards such as Burning Abyss and Fur Hire – rather than spending endless amounts of Duel Points to try and obtain them from the Card Shop. The searchable database below shows all cards currently farm-able from Challenge Mode duels and has been updated for the March 24th 2020 update content – featuring 15 new Challenge Duels from Yu-Gi-Oh! VRAINS</p>
                        <PaginationProvider pagination={ paginationFactory(options) }>
                            { contentTable }
                        </PaginationProvider>
                        <div class="advertisement-box" id="bottomLeaderboard"></div>   
                    </Col>     
                </Row>
            </Container>               



        )
    }

}

export default Challenge;