import React from 'react'
import { Container, Col, Row} from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import { RichText } from 'prismic-reactjs'
import {Link} from 'react-router-dom';

import './directory.css';


import Prismic from '@prismicio/client'
import ClipLoader from 'react-spinners/ClipLoader'

const apiEndpoint = 'https://linkevolutionpro.cdn.prismic.io/api/v2'
const client = Prismic.client(apiEndpoint)

class Directory extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            document:null,
            loading:true,
            found: true,
        };
    }

    componentDidMount(){

        client.query(
            Prismic.Predicates.at('document.type', 'article_directory'),{ orderings : '[document.last_publication_date desc]' }).then(
                res => {
                    if(res.results.length != 0){
                        this.setState({document: res.results, loading:false})
                        //console.log(res.results)
                    }
                    else{
                        this.setState({found:false})
                    }
                }
            )
        
            if(document.getElementById('NewAnchor')){
                return null
              } else{
                window['nitroAds'].createAd('NewAnchor', {
                  "demo": false, //DISABLE THIS WHEN BUILDING
                  "refreshLimit": 10,
                  "refreshTime": 30,
                  "format": "anchor",
                  "anchor": "bottom",
                  "report": {
                    "enabled": true,
                    "wording": "Report Ad",
                    "position": "top-right"
                  },
                  "mediaQuery": "(min-width: 1025px), (min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
                });
              }
    }

    componentDidUpdate(){

        window['nitroAds'].createAd('topLeaderboard', {
          "demo": false,
          "refreshLimit": 10,
          "refreshTime": 30,
          "renderVisibleOnly": false,
          "refreshVisibleOnly": true,
          "sizes": [
            [
              "320",
              "50"
            ]
          ],
          "report": {
            "enabled": true,
            "wording": "Report Ad",
            "position": "top-right"
          },
          "mediaQuery": "(min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
        });

        window['nitroAds'].createAd('topLeaderboard', {
          "demo": false,
          "refreshLimit": 10,
          "refreshTime": 30,
          "renderVisibleOnly": false,
          "refreshVisibleOnly": true,
          "sizes": [
            [
              "970",
              "90"
            ]
          ],
          "report": {
            "enabled": true,
            "wording": "Report Ad",
            "position": "top-right"
          },
          "mediaQuery": "(min-width: 1025px)"
        });

        window['nitroAds'].createAd('bottomLeaderboard', {
            "demo": false,
            "refreshLimit": 10,
            "refreshTime": 30,
            "renderVisibleOnly": false,
            "refreshVisibleOnly": true,
            "sizes": [
              [
                "728",
                "90"
              ],
              [
                "970",
                "90"
              ]
            ],
            "report": {
              "enabled": true,
              "wording": "Report Ad",
              "position": "top-right"
            },
            "mediaQuery": "(min-width: 1025px)"
          });
    
          window['nitroAds'].createAd('bottomLeaderboard', {
            "demo": false,
            "refreshLimit": 10,
            "refreshTime": 30,
            "renderVisibleOnly": false,
            "refreshVisibleOnly": true,
            "sizes": [
              [
                "300",
                "250"
              ],
              [
                "300",
                "600"
              ]
            ],
            "report": {
              "enabled": true,
              "wording": "Report Ad",
              "position": "top-right"
            },
            "mediaQuery": "(min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
          });
    }

    render(){
        
        if(this.state.loading != true){

            const articleList = this.state.document.map((article, index) =>{
                //console.log(article)

                if(article.data.link.type === "deck"){
                    return(
                        <Col md={6} lg={6} id="articleCol">
                         <Link to={"/guide/" + article.data.link.uid} >
                        <div className="article-box">
                            <h3 className="article-title">{article.data.article_title[0].text}</h3>
                            <Image src={article.data.article_image.url} alt={article.data.article_image.alt} fluid id="article-image"></Image> 
                        </div>
                    </Link>
                    </Col>
                    )
                }

                return (
                    <Col md={6} lg={6} id="articleCol">
                    <Link to={"/article/" + article.data.link.uid} >
                        <div className="article-box">
                            <h3 className="article-title">{article.data.article_title[0].text}</h3>
                            <Image src={article.data.article_image.url} alt={article.data.article_image.alt} fluid id="article-image"></Image> 
                        </div>
                    </Link>
                    </Col>
                )
                
            })


            return(
                <Container field="md" className="AboutPage">
                    <Row id="content">
                        <Col id="about-content" lg={12} md={12}>
                            <div class="advertisement-box" id="topLeaderboard"></div>
                        </Col> 
                            {articleList}
                        <Col id="about-content" lg={12}  md={12}>
                            <div class="advertisement-box" id="bottomLeaderboard"></div>
                        </Col>
                    </Row>
                </Container>
            )

        }

        else{
            return(
                <div>
                    <ClipLoader color = {"#ffd780"} loading = {true} ></ClipLoader>

                </div>
            )
        }


    }
}

export default Directory