import React from 'react'
import {Navbar, Nav, NavDropdown, Container} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';

import './Navbar.css';

class FooterExport extends React.Component{
    render(){

        return(
                <Navbar variant="dark" id="navbottom" >
                    <Container>
                    <Navbar.Brand as={NavLink} to="/"> LinkEvolutionPro </Navbar.Brand>
                        <Nav className="ml-auto">
                            <Nav.Link eventKey="1" as={NavLink} to="/about-us"> About Us </Nav.Link>
                            <Nav.Link eventKey="2" as={NavLink} to="/privacy-policy-2"> Privacy Policy </Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
        );
    }
}

export default FooterExport;