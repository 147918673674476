import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';

import Analytics from 'react-router-ga';

import Home from './components/Home';
import About  from './components/About';
import PrivacyPolicy  from './components/PrivacyPolicy';
import CardShop  from './components/cardshop';
import BanList  from './components/banlist';
import Article from './components/article';
import Challenge from './components/challenge';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarExport from './components/Navbar';
import FooterExport from './components/Footer'

import DeckArticle from './components/deckarticle';
import Test from './components/testing';


import Directory from './components/Directory';

import './App.css';

import ScrollIntoView from './components/modules/ScrollIntoView';

function App() {
  
  return (
    <React.Fragment>
      <Router>

        <ScrollIntoView>

        <NavbarExport></NavbarExport>
          <Analytics id="UA-165403550-1">
            <Switch >

              <Route exact path="/" component={Directory} />
              <Route path="/About-Us" component={About} />
              <Route path="/Privacy-Policy-2" component={PrivacyPolicy} />
              <Route path="/Updated-Card-Shop-Guide" component={CardShop} />
              <Route path="/challenge-mode-guide" component={Challenge}/>
              <Route path="/Forbidden-Limited-List" component={BanList}/>
              <Route path="/Articles" component={Directory}/>

              <Route path="/Guide/:id" component={DeckArticle} />
              <Route path="/Article/:id" component={Article} />

              <Redirect from="/konami-announces-new-yu-gi-oh-rush-duel-game-in-development-2021" to='/Article/konami-announces-new-yu-gi-oh-rush-duel-game-in-development-2021'></Redirect>
              <Redirect from="/will-yu-gi-oh-legacy-of-the-duelist-link-evolution-receive-an-update" to='/Article/will-yu-gi-oh-legacy-of-the-duelist-link-evolution-receive-an-update'></Redirect>
              <Redirect from="/konami-announces-new-yu-gi-oh-game-in-development" to='/Article/konami-announces-new-yu-gi-oh-game-in-development'></Redirect>
              <Redirect from="/new-update-march-24th-heres-whats-new" to='/Article/march-24th-update'></Redirect>
              <Redirect from="/master-rule-5" to='/Article/master-rule-5-guide'></Redirect>

              <Redirect from="/x_Article/:id" to='/Article/:id'/>

              <Route path="/Test" component={Test} />
              

              <Route path="*" component={Directory} /> {/* Replace this with a redirect to a 404 Not found page. */}
            
            </Switch>
          </Analytics>
        {/*<FooterExport></FooterExport> */}
        </ScrollIntoView>
      </Router>
    </React.Fragment>
  );
}

export default App;
