import React from 'react'
import { Container, Col, Row} from 'react-bootstrap'
import './about.css';

import ClipLoader from 'react-spinners/ClipLoader'
import Prismic from '@prismicio/client'
import { RichText } from 'prismic-reactjs'

const apiEndpoint = 'https://linkevolutionpro.cdn.prismic.io/api/v2'

const client = Prismic.client(apiEndpoint)

class Home extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            document:null,
            loading:true
        };
    }

    componentDidMount(){

        client.query(
            Prismic.Predicates.at('document.type', 'important_update')).then(
                res => {
                    if(res.results.length != 0){
                        this.setState({document: res.results[0], loading:false})
                    }
                    else{
                        console.log("weeeeeeeeeeeee")
                    }
                }
            )
        
            if(document.getElementById('NewAnchor')){
                return null
              } else{
                window['nitroAds'].createAd('NewAnchor', {
                  "demo": false, //DISABLE THIS WHEN BUILDING
                  "refreshLimit": 10,
                  "refreshTime": 30,
                  "format": "anchor",
                  "anchor": "bottom",
                  "report": {
                    "enabled": true,
                    "wording": "Report Ad",
                    "position": "top-right"
                  },
                  "mediaQuery": "(min-width: 1025px), (min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
                });
              }

    }

    componentDidUpdate(){

        window['nitroAds'].createAd('topLeaderboard', {
          "demo": false,
          "refreshLimit": 10,
          "refreshTime": 30,
          "renderVisibleOnly": false,
          "refreshVisibleOnly": true,
          "sizes": [
            [
              "320",
              "50"
            ]
          ],
          "report": {
            "enabled": true,
            "wording": "Report Ad",
            "position": "top-right"
          },
          "mediaQuery": "(min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
        });
        window['nitroAds'].createAd('bottomLeaderboard', {
            "demo": false,
            "refreshLimit": 10,
            "refreshTime": 30,
            "renderVisibleOnly": false,
            "refreshVisibleOnly": true,
            "sizes": [
              [
                "728",
                "90"
              ],
              [
                "970",
                "90"
              ]
            ],
            "report": {
              "enabled": true,
              "wording": "Report Ad",
              "position": "top-right"
            },
            "mediaQuery": "(min-width: 1025px)"
          });
    
          window['nitroAds'].createAd('bottomLeaderboard', {
            "demo": false,
            "refreshLimit": 10,
            "refreshTime": 30,
            "renderVisibleOnly": false,
            "refreshVisibleOnly": true,
            "sizes": [
              [
                "300",
                "250"
              ]
            ],
            "report": {
              "enabled": true,
              "wording": "Report Ad",
              "position": "top-right"
            },
            "mediaQuery": "(min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
          });
    }

    render(){

        if(this.state.loading != true){
            return(
                <Container field="md" className="AboutPage">
                    <Row id="content">
                        <Col id="about-content">
                            <div class="advertisement-box" id="topLeaderboard"></div> 
                            <RichText render={this.state.document.data.title} Component="h4"></RichText>
                            <RichText render={this.state.document.data.content} Component="p"></RichText>
                            <div class="advertisement-box" id="bottomLeaderboard"></div> 
                        </Col>
                    </Row>
                </Container>
            )
        }

        else{
            return (
                <div>
                    <ClipLoader color = {"#ffd780"} loading = {true} ></ClipLoader>
                </div>
            )
        }  

    }
}

export default Home;

