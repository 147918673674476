import React from 'react'
import { Container, Col, Row} from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import './about.css';

import ClipLoader from 'react-spinners/ClipLoader'
import Prismic from '@prismicio/client'
import { RichText } from 'prismic-reactjs'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import './table.css';

const columns = [{
    dataField: 'CardName',
    text: 'Card Name',
    headerStyle: {
        backgroundColor: '#1d1f29',
        color: 'white'
    }
}, {
    dataField: 'Quantity',
    text: 'Quantity',
    headerStyle: {
        backgroundColor: '#1d1f29',
        color: 'white'
    }
}, {
    dataField: 'Location',
    text: 'Deck Location',
    headerStyle: {
        backgroundColor: '#1d1f29',
        color: 'white'
    }
}];

const rowStyle = {
    backgroundColor: '#272937',
    color: 'white',
}

const { SearchBar } = Search;

const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    showTotal: true
  };

const apiEndpoint = 'https://linkevolutionpro.cdn.prismic.io/api/v2'

const client = Prismic.client(apiEndpoint)

class DeckArticle extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            document:null,
            loading:true,
            found:true,
        };
    }

    componentDidMount(){

        const articleID = this.props.match.params.id;

        client.query(
            Prismic.Predicates.at('my.deck.uid', articleID)).then(
                res => {
                    if(res.results.length != 0){
                        this.setState({document: res.results[0], loading:false})
                        //console.log(res.results[0])
                    }
                    else{
                        this.setState({found:false})
                    }
                }
            ) 
        
              if(document.getElementById('NewAnchor')){
                return null
              } else{
                window['nitroAds'].createAd('NewAnchor', {
                  "demo": false, //DISABLE THIS WHEN BUILDING
                  "refreshLimit": 10,
                  "refreshTime": 30,
                  "format": "anchor",
                  "anchor": "bottom",
                  "report": {
                    "enabled": true,
                    "wording": "Report Ad",
                    "position": "top-right"
                  },
                  "mediaQuery": "(min-width: 1025px), (min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
                });
              }
    }

    componentDidUpdate(){

        window['nitroAds'].createAd('topLeaderboard', {
          "demo": false,
          "refreshLimit": 10,
          "refreshTime": 30,
          "renderVisibleOnly": false,
          "refreshVisibleOnly": true,
          "sizes": [
            [
              "320",
              "50"
            ]
          ],
          "report": {
            "enabled": true,
            "wording": "Report Ad",
            "position": "top-right"
          },
          "mediaQuery": "(min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
        });

        window['nitroAds'].createAd('topLeaderboard', {
          "demo": false,
          "refreshLimit": 10,
          "refreshTime": 30,
          "renderVisibleOnly": false,
          "refreshVisibleOnly": true,
          "sizes": [
            [
              "970",
              "90"
            ]
          ],
          "report": {
            "enabled": true,
            "wording": "Report Ad",
            "position": "top-right"
          },
          "mediaQuery": "(min-width: 1025px)"
        });

        window['nitroAds'].createAd('bottomLeaderboard', {
            "demo": false,
            "refreshLimit": 10,
            "refreshTime": 30,
            "renderVisibleOnly": false,
            "refreshVisibleOnly": true,
            "sizes": [
              [
                "728",
                "90"
              ],
              [
                "970",
                "90"
              ]
            ],
            "report": {
              "enabled": true,
              "wording": "Report Ad",
              "position": "top-right"
            },
            "mediaQuery": "(min-width: 1025px)"
          });
    
          window['nitroAds'].createAd('bottomLeaderboard', {
            "demo": false,
            "refreshLimit": 10,
            "refreshTime": 30,
            "renderVisibleOnly": false,
            "refreshVisibleOnly": true,
            "sizes": [
              [
                "300",
                "250"
              ],
              [
                "300",
                "600"
              ]
            ],
            "report": {
              "enabled": true,
              "wording": "Report Ad",
              "position": "top-right"
            },
            "mediaQuery": "(min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
          });
    }

    render(){

        if(this.state.loading != true){

            const blogContent = this.state.document.data.body.map((slice, index) => {

              if (slice.slice_type === 'text_content') {
                  //return <RichText render={slice.content} Component="p"></RichText>
                  //console.log(slice)
                  const textContent = slice.items.map((text, textIndex) => (
                    <div key={textIndex}>
                        <RichText render={text.sub_heading} Component="h3"></RichText>
                        <RichText render={text.content} Component="p"></RichText>
                    </div>
                  ));
                  return (
                      <div>
                        {textContent}
                      </div>
                  )
              }
              
              if (slice.slice_type === "image"){
                  //console.log(slice.primary.image.url)
                  return(
                      <div>
                          <Image src={slice.primary.image.url} alt={slice.primary.image.alt} fluid id="blogImage"></Image>
                      </div>
                    
                  )
              }

              if (slice.slice_type === "deck_collection"){
                  const tableData = slice.items.map((data, dataIndex) => (
                      {CardName: data.card_name, Quantity: data.card_quantity, Location: data.deck}
                  ))
                  return(
                    <div>
                        <h3>{slice.primary.deck_type}</h3>
                          <ToolkitProvider
                            keyField="Name"
                            columns={ columns }
                            data={ tableData }
                            search
                          >
                            {
                              toolkitprops => (
                                <div>
                                  <SearchBar { ...toolkitprops.searchProps } />
                                  <BootstrapTable
                                    striped
                                    condensed
                                    bordered={ false }
                                    { ...toolkitprops.baseProps }
                                    //{ ...paginationTableProps }
                                    rowStyle={ rowStyle }
                                  />
                                </div>
                              )
                            }
                          </ToolkitProvider>
                          <PaginationListStandalone />
                        </div>
                  )
              }

              else {
                 return null;
             }
            
            });


            return(
                <Container field="md" className="AboutPage">
                    <Row id="content">
                        <Col id="about-content">
                            <div class="advertisement-box" id="topLeaderboard"></div> 
                            <RichText render={this.state.document.data.title} Component="h4"></RichText>
                            {blogContent}
                            <div class="advertisement-box" id="bottomLeaderboard"></div>
                        </Col>
                    </Row>
                </Container>
            )
        }

        if (this.state.found === false) {
            return(
                <div>
                    <h3>Error. Page not found. Please go home and try again :)</h3>
                </div>
            )
        }

        else{
            return (
                <div>
                    <ClipLoader color = {"#ffd780"} loading = {true} ></ClipLoader>
                </div>
            )
        }
        
    }

}

export default DeckArticle;
