import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import CardData from '../components/data/banlistdata.json';
import './table.css';

const columns = [{
    dataField: 'Card Name',
    text: 'Card Name',
    headerStyle: {
        backgroundColor: '#1d1f29',
        color: 'white'
    }
}, {
    dataField: 'Card Type',
    text: 'Card Type',
    headerStyle: {
        backgroundColor: '#1d1f29',
        color: 'white'
    }
}, {
    dataField: 'Status',
    text: 'Card Status',
    headerStyle: {
        backgroundColor: '#1d1f29',
        color: 'white'
    }
}];

const rowStyle = {
    backgroundColor: '#272937',
    color: 'white',
}

const { SearchBar } = Search;


class Banlist extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            //cards:CardData,
        };
    }

    componentDidMount(){

      window['nitroAds'].createAd('topLeaderboard', {
        "demo": false,
        "refreshLimit": 10,
        "refreshTime": 30,
        "renderVisibleOnly": false,
        "refreshVisibleOnly": true,
        "sizes": [
          [
            "320",
            "50"
          ]
        ],
        "report": {
          "enabled": true,
          "wording": "Report Ad",
          "position": "top-right"
        },
        "mediaQuery": "(min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
      });

      window['nitroAds'].createAd('topLeaderboard', {
        "demo": false,
        "refreshLimit": 10,
        "refreshTime": 30,
        "renderVisibleOnly": false,
        "refreshVisibleOnly": true,
        "sizes": [
          [
            "970",
            "90"
          ]
        ],
        "report": {
          "enabled": true,
          "wording": "Report Ad",
          "position": "top-right"
        },
        "mediaQuery": "(min-width: 1025px)"
      });
      
      window['nitroAds'].createAd('bottomLeaderboard', {
        "demo": false,
        "refreshLimit": 10,
        "refreshTime": 30,
        "renderVisibleOnly": false,
        "refreshVisibleOnly": true,
        "sizes": [
          [
            "728",
            "90"
          ],
          [
            "970",
            "90"
          ]
        ],
        "report": {
          "enabled": true,
          "wording": "Report Ad",
          "position": "top-right"
        },
        "mediaQuery": "(min-width: 1025px)"
      });

      window['nitroAds'].createAd('bottomLeaderboard', {
        "demo": false,
        "refreshLimit": 10,
        "refreshTime": 30,
        "renderVisibleOnly": false,
        "refreshVisibleOnly": true,
        "sizes": [
          [
            "300",
            "250"
          ],
          [
            "300",
            "600"
          ]
        ],
        "report": {
          "enabled": true,
          "wording": "Report Ad",
          "position": "top-right"
        },
        "mediaQuery": "(min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
      });

      if(document.getElementById('NewAnchor')){
        return null
      } else{
        window['nitroAds'].createAd('NewAnchor', {
          "demo": false, //DISABLE THIS WHEN BUILDING
          "refreshLimit": 10,
          "refreshTime": 30,
          "format": "anchor",
          "anchor": "bottom",
          "report": {
            "enabled": true,
            "wording": "Report Ad",
            "position": "top-right"
          },
          "mediaQuery": "(min-width: 1025px), (min-width: 768px) and (max-width: 1024px), (min-width: 320px) and (max-width: 767px)"
        });
      }
      
    }

    render(){

        const options = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            showTotal: true,
            totalSize: CardData.length
          };
                
          const contentTable = ({ paginationProps, paginationTableProps }) => (
            <div>
              <ToolkitProvider
                keyField="Card Name"
                columns={ columns }
                data={ CardData }
                search
              >
                {
                  toolkitprops => (
                    <div>
                      <SearchBar { ...toolkitprops.searchProps } />
                      <BootstrapTable
                        striped
                        condensed
                        bordered={ false }
                        { ...toolkitprops.baseProps }
                        { ...paginationTableProps }
                        rowStyle={ rowStyle }
                      />
                    </div>
                  )
                }
              </ToolkitProvider>
              <PaginationListStandalone { ...paginationProps } />
            </div>
          );
      

        return(

            <Container field="md" className="AboutPage">
                <Row id="content">
                    <Col id="about-content">
                        <div class="advertisement-box" id="topLeaderboard"></div>
                        <h4>Forbidden & Limited List</h4>
                        <h3>Last Update: March 23rd 2020</h3>
                        <p>Comprised of a mixture of January 2020’s TCG and OCG Yu-Gi-Oh Forbidden and Limited lists, Legacy of the Duelist Link Evolution implements a “World Championship Format” list, which has never been seen before in an official Yu-Gi-Oh! Trading Card Game video game. Take a look at what cards you can and can’t use in Online Ranked Duels from March 24th 2020 and onward down below!</p>
                        <PaginationProvider pagination={ paginationFactory(options) }>
                            { contentTable }
                        </PaginationProvider>

                        <div class="advertisement-box" id="bottomLeaderboard"></div>
                        
                    </Col>     
                </Row>
            </Container>               



        )
    }

}

export default Banlist;