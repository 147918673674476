import React from 'react'
import { Container, Col, Row} from 'react-bootstrap'
import './about.css';

import ClipLoader from 'react-spinners/ClipLoader'
import Prismic from '@prismicio/client'
import { RichText } from 'prismic-reactjs'

const apiEndpoint = 'https://linkevolutionpro.cdn.prismic.io/api/v2'

const client = Prismic.client(apiEndpoint)

class About extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            document:null,
            loading:true
        };
    }

    componentDidMount(){

        client.query(
            Prismic.Predicates.at('document.type', 'about_us')).then(
                res => {
                    if(res.results.length != 0){
                        this.setState({document: res.results[0], loading:false})
                        //console.log(res.results[0])
                    }
                    else{
                        console.log("weeeeeeeeeeeee")
                    }
                }
            ) 

    }

    render(){

        if(this.state.loading != true){
            return(
                <Container field="md" className="AboutPage">
                    <Row id="content">
                        <Col id="about-content">
                            <RichText render={this.state.document.data.page_title} Component="h4"></RichText>
                            <RichText render={this.state.document.data.page_content} Component="p"></RichText>
                            <RichText render={this.state.document.data.sub_heading} Component="h2"></RichText>
                            <RichText render={this.state.document.data.sub_content} Component="p"></RichText>
                        
                        </Col>
                    </Row>
                </Container>
            )
        }

        else{
            return (
                <div>
                    <ClipLoader color = {"#ffd780"} loading = {true} ></ClipLoader>
                </div>
            )
        }  

    }
}

export default About;
